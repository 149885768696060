import React from 'react'
import '../index.css'
import { Link } from 'react-scroll';
function Introduction() {
    return (
        <div className="Intro p-5">
            <div className="Introinside">
            <h1 >Hello, my name is <strong style={{ color : "#C36A2D" }}> Parth Mistry</strong>, </h1>
            <h1>i am a full stack developer</h1>
            <div className="underline p-3" ></div>
            <li className="btn m-5" style={{ backgroundColor :'#EADCA6', color :'#C36A2D' }} ><Link
        to="About"
        activeClass="active"
        spy={true} 
        smooth={true}
    >Know More     <i class="fas fa-angle-double-right"></i></Link></li>
            </div>
            
        </div>
    )
}

export default Introduction
