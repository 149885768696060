import About from "./components/About";
import Introduction from "./components/Introduction";
import Navbar from "./components/Navbar";

function App() {
  return (
    
    <div>
      <Introduction/>
      <About/>
    </div>
  );
}

export default App;
